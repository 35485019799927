import { BrowserRouter as Router } from 'react-router-dom'
import { AppRoutes } from 'routes/Routes'
import { useContext } from 'react'
import { AppContext } from 'context'
import { MantineProvider } from '@mantine/core'
// import botLogo from '../src/assets/logo/favicon-black.png'
import { BiBot } from 'bibot'
function App () {
  const { state } = useContext(AppContext)
  return (
    <MantineProvider>
      <div className={`App ${state.theme}`}>
        <Router>
          <AppRoutes />
        </Router>
        <BiBot clientId={'6c74b4bb-0395-4751-a55f-7d065d67c56b'} />
      </div>
    </MantineProvider>
  )
}

export default App
